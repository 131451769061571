<!-- 
	Refacted 26/12
 -->


<template>

	<div class="pld-number-box">

		<div v-if="label">
			{{ label }}
		</div>

		<div class="pld-number-tool">

			<svg v-show="value" @click="update('decrement')" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="9.06" cy="9.06" r="9.06" fill="#4F8A8B"/>
			<rect x="5.45311" y="8.17625" width="7.05728" height="0.641571" stroke="white" stroke-width="0.641571"/>
			</svg>


			<div class="pdl-number-result">
				{{val}}
			</div>
			<input 
				type="hidden" 
				:value="val"
				@change="$emit('change', parseInt(this.data))" 
				disabled
			/>

			<svg @click="update('increment')" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="9.06" cy="9.06" r="9.06" fill="#4F8A8B"/>
			<path d="M9.53302 8.93269L12.3329 8.93269L12.3329 9.03265L9.53302 9.03265L9.03302 9.03265L9.03302 9.53265L9.03302 12.3325L8.93306 12.3325L8.93306 9.53265L8.93306 9.03265L8.43306 9.03265L5.63318 9.03265L5.63318 8.93269L8.43306 8.93269L8.93306 8.93269L8.93306 8.43269L8.93306 5.63281L9.03302 5.63281L9.03302 8.43269L9.03302 8.93269L9.53302 8.93269Z" fill="#07031A" stroke="white"/>
			</svg>

		</div>
	</div>
</template>

<script>
	export default {
		
  		props: {   
	        label: String,
	        value: [Array, Boolean, String, Number]
	     },

		
		data() {
			return {
				data : 0
			}
		},

		computed : {
			val(){
				return this.data ? this.data : (this.value ? this.value : 0)
			}
		},

		methods:{
			update(operator){

				if (!this.data) {
					this.data= this.value
				}

				if ( operator === "increment" ) {
					this.data++
				} else if ( operator != "increment" && this.data > 0 ) {
					this.data--
				}
				this.$emit('input', parseInt(this.data))
			},
		}
	}
	
</script>

<style>
	.pld-number-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
	    font-size: 1.3rem;
    	margin-top: 1.7rem;
	}
	/* counter */
	.pld-number-tool {
		display: flex;
		align-items: center;
	}
	.pld-number-tool > .pdl-number-result {
	    text-align: center;
	    font-size: 2rem;
	    line-height: 0;
	    border: none;
	    padding: 0 .5rem;
    	min-width: 35px;
	}

	.pld-number-box svg {
		width: 1.8rem
	}
</style>