<template>
    <form>
      <h1>Logement</h1>
      <br>
      
      <h2>ID logement</h2>
      <p>{{ property.id }}</p>

      <h2>ID propriétaire</h2>
      <v-btn class="info" @click="openUser(property.ownerUid)">{{ property.owner }}</v-btn>

      <h2>Url de l'annonce</h2>
      <a target="blank" :href="propertyUrl">
        {{ propertyUrl }}
      </a>
      <br>
      <br>
      <h2>Loyer Hors Charges</h2>
      <p>{{ property.rent }}</p>
      <br>
      <br>
      
      <h1>Emplacement et caractéristiques</h1>
      <br>
      <h2>Quel est le type de votre logement ?</h2>
      <v-select
        v-model="property.type"
        :items="items"
        item-text="label"
        item-value="id"
        label="Type"
        required
        @change="setType()"
      ></v-select>
      <v-select
        v-model="property.subtype"
        :items="subtypes"
        item-text="label"
        item-value="id"
        label="Sous-type"
        required
      ></v-select>
      <br>
      <h2>Quel est votre bilan énergétique ?</h2>
      <v-select
        v-model="property.heating"
        :items="heatings"
        item-text="label"
        item-value="id"
        label="Types de chauffages"
        required
      ></v-select>
      <v-select
        v-model="property.energy"
        :items="energies"
        item-text="label"
        item-value="id"
        label="Consommation d'énergie"
        required
      ></v-select>
      <v-select
        v-model="property.emissions"
        :items="emissions"
        item-text="label"
        item-value="id"
        label="Émissions de gaz à effet de serre"
        required
      ></v-select>
      <br>

      <h2>Où est situé votre logement ?</h2>
      <google-map-autocomplete
        label="Adresse"
        id="pac-card"
        :model="property.address"
        :controls="false"
        :update-map="false"
        @place-changed="changePlace"
      ></google-map-autocomplete>
      <br>

      <h2>Quelles sont les dimensions de votre logement ?</h2>
      <v-text-field
        v-model="property.surface"
        label="surface"
        required
        
      ></v-text-field>
      <v-text-field
        v-model="property.floor"
        label="Nombre d'étages"
        required
        
      ></v-text-field>
      <v-text-field
        v-model="property.rooms"
        label="Nombre de pièces"
        required
        
      ></v-text-field>
      <v-text-field
        v-model="property.bedrooms"
        label="Nombre de chambres"
        required
        
      ></v-text-field>
      <br>

      <h1>Équipements et caractéristiques</h1>
      <br>
      <h2>Quel type de bail ?</h2>

      <v-radio-group
          v-model="property.furnished"
          row
          >
          <v-radio
            label="Meublé"
            :value="true"
          ></v-radio>
          <v-radio
            label="Non-meublé"
            :value="false"
          ></v-radio>
      </v-radio-group>
      <br>
      <h2>De quels équipements disposez vous ?</h2>
      <br>
      <base-pannel title="Equipements">
        <div v-for="(equipment, i) in equipments" :key="i">

          <base-checkbox
            v-if="equipment.display == 1"
            display = "switch"
            :label = "equipment.label"
            :val = "{ propertyEquipment: equipment.id, count : null }"
            v-model = "property.equipmentsLinks"
          />
          
          <base-number 
            v-if="equipment.display == 2"
            :label = "equipment.label"
            :value="getNumber(equipment.id)"
            @input="setNumber($event, equipment.id)"
          />
        </div>
      </base-pannel>
      <br>

      <br>
      <h2>Services inclus dans les charges ?</h2>
      <br>
      <base-pannel title="Services">
        <div
          v-for="service in services"
          :key="service.id"
        >

          <base-checkbox
            display="switch"
            :label="service.label"
            :val = "{ propertyService: service.id }" 
            v-model = "property.servicesLinks"
          />
        </div>
      </base-pannel>

      <br>
      <h2>Avantages de votre logement ?</h2>
      <br>
      <base-pannel title="Avantages">
        <div
          v-for="benefit in benefits"
          :key="benefit.id"
        >

          <base-checkbox
            display="switch"
            :label="benefit.label"
            :val ="{ propertyBenefit: benefit.id }" 
            v-model ="property.benefitsLinks"
          />
        </div>
      </base-pannel>
      <br>
      <h1>Loyer et paiements</h1>
      <br>
      <h2>Fixez un prix pour votre logement</h2>
      <v-text-field
        v-model="property.rent"
        label="Loyer mensuel hors charges"
        required
        
      ></v-text-field>
      <v-text-field
        v-model="property.charges"
        label="Charges mensuelles"
        required
        
      ></v-text-field>
      <v-text-field
        v-model="property.deposit"
        label="Dépôt de garantie"
        required
      ></v-text-field>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            label="date de disponibilité"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="property.availability"
          @input="menu = false"
          locale="fr-fr"
        ></v-date-picker>
      </v-menu>
      <br>
      <h1>Description, photos et Vidéos</h1>
      <v-container fluid>
      <h2>Ajouter un description à votre annonce</h2>
        <v-textarea
          v-model="property.description"
          name="input-7-1"
          filled
          label="La description de votre annonce"
          auto-grow
        ></v-textarea>
        <h2>Donnez un titre à votre annonce</h2>
        <v-textarea
          name="input-7-1"
          filled
          label="Le titre de votre annonce"
          auto-grow
          v-model="property.title"
        ></v-textarea>
        <h2>Ajoutez votre numéro de téléphone</h2>
        <v-text-field
        type="Téléphone"
        v-model="property.ownerPhone"
        label="Numéros de téléphone"
        required
        
      ></v-text-field>
      <v-radio-group
          v-model="property.phoneHiding"
          row
          >
          <v-radio
            label="Masqué"
            :value="true"
          ></v-radio>
          <v-radio
            label="Non-masqué"
            :value="false"
          ></v-radio>
      </v-radio-group>
      </v-container>
      <h2>Ajouter des photos à votre annonce</h2>
        <v-file-input
          accept="image/png,image/jpeg"
          label="File input"
          v-model="photo"
          @change="addPhoto()"
        />
        <div
          v-for="(photo, i) in property.photos"
          :key="i"
          class="containerPhotos"
        >
        <img class="photos" 
          :src="BaseUrl + photo.url" 
          alt="" 
          >
        <div class="photosButton">
          <v-btn
            color="error" 
            @click="deleteItems(i, property.photos)"
            >delete
          </v-btn>
          <v-btn
            @click="openPhotos(BaseUrl + photo.url.replace(/@\d+x\d+/, '@1600x1200'))"
          >open
          </v-btn>
        </div>
        </div>
      <h2>Ajouter des vidéos à votre annonce</h2>
      <v-file-input
          accept="video/mp4,video/mpeg"
          label="video input"
          v-model="video"
          @change="addVideo()"
        ></v-file-input>
        <v-text-field
          v-model="property.video"
          label="Ajouter un lien vers une vidéo"
          placeholder="Lien vidéo"
        />
        <a v-if="property.video" target="_blank" :href="property.video">{{ property.video }}</a>
      <div
        v-for="(video, i) in property.videos"
        :key="i"
      >
        <video controls class="videos">
          <source 
            :src="BaseUrl + video.url"
          >
        </video>
        <div>
          <v-btn 
            color="error" 
            @click="deleteItems(i, property.videos)"
            >delete
          </v-btn>
        </div>
      </div>
      <br>
      <h1>Informations pour les candidatures</h1>
      <h2>Préférences pour candidature</h2>
      <br>
      <base-pannel title="Préférences">
        <div
          v-for="preference in preferences"
          :key="preference.id"
        >

          <base-checkbox
            display="switch"
            :label="preference.label"
            :val = "{ propertyPreference: preference.id }" 
            v-model = "property.preferencesLinks"
          />
        </div>
      </base-pannel>
      <br>
      <h1>Channel de diffusion</h1>
      <v-select
        v-model="property.channelsLinks"
        :items="channels"
        item-text="label"
        item-value="id"
        label="Channel de diffusions"
        required
        multiple
      ></v-select>
      <base-back-to-top/>
      <br>
      <v-radio-group
          v-model="property.available"
          row
          >
          <v-radio
            label="Activé"
            :value="true"
          ></v-radio>
          <v-radio
            label="Désactivé"
            :value="false"
          ></v-radio>
      </v-radio-group>
      <br>
      <div class="d-flex mb-6" height="100">
        <v-btn
          class="info mr-2"
          @click="submit()"
        >
          Valider
        </v-btn>
        <v-btn
          class="error"
          @click="deleteProperty()"
        >
          Supprimer ce logement
        </v-btn>
      </div>
    </form>
</template>

<script>
  import axios from 'axios'
  import axiosGetDataWithUid from '@/apiCall/axiosGetDataWithUid.js'
  import axiosGetListData from '@/apiCall/axiosGetList.js'
  import BaseNumber from '../../components/BaseNumber.vue'
  import BaseCheckbox from '../../components/BaseCheckbox.vue'
  import BasePannel from '@/components/BasePannel'
  import BaseBackToTop from '../../components/BaseBackToTop.vue'


export default {
  components: { BaseNumber, BaseCheckbox, BasePannel, BaseBackToTop },
    data:() => ({
      BaseUrl: process.env.VUE_APP_BASEURL,
      property: {},
      items: [],
      subtypes: [],
      // ownerTypes: [],
      heatings: [],
      emissions: [],
      energies: [],
      equipments: [],
      preferences: [],
      services: [],
      benefits: [],
      channels: [],
      photo: {},
      video: {},
      urlVideo: '',
      menu: false,
    }),

    created(){
      axiosGetDataWithUid('/properties/', this.$route.params.propertyUid)
      .then(response => {
        this.property = Object.assign(response.data, {
            availability: response.data.availability ? new Date(response.data.availability).toISOString().substr(0,10) : '',
            channelsLinks: response.data.channelsLinks.map(channel => channel.propertyChannel),
          },
        )

        if ( this.property.equipmentsLinks ) {
					this.property.equipmentsLinks = this.property.equipmentsLinks.map( equpmt => { 
						return { propertyEquipment: equpmt.propertyEquipment, count : equpmt.count }
          })
        }

        if ( this.property.preferencesLinks ) {
					this.property.preferencesLinks= this.property.preferencesLinks.map( pref => { 
						return { propertyPreference: pref.propertyPreference }
					})
        }

        if ( this.property.servicesLinks ) {
					this.property.servicesLinks= this.property.servicesLinks.map( serv => { 
						return { propertyService: serv.propertyService }
					})
        }

        if ( this.property.benefitsLinks ) {
					this.property.benefitsLinks= this.property.benefitsLinks.map( benefit => { 
						return { propertyBenefit: benefit.propertyBenefit }
					})
        }

        if(this.property.type){
          axiosGetListData(this, '/propertiesSubtypes', 'subtypes', {
          params :{
            propertyType: this.property.type
          }})
        }
        axiosGetListData(this, '/propertiesTypes', 'items')
        axiosGetListData(this, '/propertiesEnergies', 'energies')
        axiosGetListData(this, '/propertiesEmissions', 'emissions')
        axiosGetListData(this, '/propertiesHeatings', 'heatings')
        axiosGetListData(this, '/propertiesEquipments', 'equipments')
        axiosGetListData(this, '/propertiesServices', 'services')
        axiosGetListData(this, '/propertiesBenefits', 'benefits')
        axiosGetListData(this, '/propertiesPreferences', 'preferences')
        // axiosGetListData(this, '/propertiesOwnersTypes', 'ownerTypes')
        axiosGetListData(this, '/propertiesChannels', 'channels')
      })
    },

    computed:{
      propertyUrl(){
        return `${this.BaseUrl}/location/${this.property.locality}-${this.property.postalCode}/${this.property.propertyTypeLabel}/${this.property.rooms}-pieces/${this.property.uid}/`
      },

      computedDateFormatted () {
        return this.formatDate(this.property.availability)
      },
    },

    methods: {
      submit () {
          axios.put(process.env.VUE_APP_APIURL + '/properties/' + this.$route.params.propertyUid, {
            type: this.property.type ? this.property.type : undefined,
            subtype: this.property.subtype ? this.property.subtype : undefined,
            heating: this.property.heating ? this.property.heating : undefined,
            energy: this.property.energy ? this.property.energy : undefined,
            emissions: this.property.emissions ? this.property.emissions : undefined,
            place: this.property.place ? this.property.place : undefined,
            address: this.property.address ? this.property.address : undefined,
            surface: this.property.surface ? this.property.surface : undefined,
            floor: this.property.floor ? this.property.floor : undefined,
            rooms: this.property.rooms ? this.property.rooms : undefined,
            bedrooms: this.property.bedrooms ? this.property.bedrooms : undefined,
            furnished: this.property.furnished !== null ? this.property.furnished : undefined,
            rent: this.property.rent ? this.property.rent : undefined,
            charges: this.property.charges ? this.property.charges : undefined,
            deposit: this.property.deposit ? this.property.deposit : undefined,
            // availability: this.property.availability ? new Date(this.property.availability.split('/').reverse().join('-')) : undefined,
            availability: this.property.availability ? new Date(this.property.availability) : undefined,
            title: this.property.title ? this.property.title : undefined,
            description: this.property.description ? this.property.description : undefined,
            phoneNumber: this.property.phoneNumber ? this.property.phoneNumber : undefined,
            phoneHiding: this.property.phoneHiding !== null ? this.property.phoneHiding : undefined,
            photos: this.property.photos ? this.property.photos : undefined,
            videos: this.property.videos ? this.property.videos : undefined,
            video: this.property.video !== '' ? this.property.video : null,
            ownerType: this.property.ownerType ? this.property.ownerType : undefined,
            // owners: this.property.owners ? this.property.owners : undefined,
            equipmentsLinks: this.property.equipmentsLinks ? this.property.equipmentsLinks : undefined,
            servicesLinks: this.property.servicesLinks ? this.property.servicesLinks : undefined,
            preferencesLinks: this.property.preferencesLinks ? this.property.preferencesLinks : undefined,
            benefitsLinks: this.property.benefitsLinks ? this.property.benefitsLinks : undefined,
            channelsLinks: this.property.channelsLinks.map(channel => ({ propertyChannel : channel })),
            available: this.property.available !== null ? this.property.available : undefined,
          },{
          headers :{
            'Access-Token': localStorage.getItem('token')
          }
        }).then(res => {

						if ( res.status == 200 ) {
              document.location.reload();
            }
          })
        .catch(err => {
          console.log(err.response.data)
        })
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },


      changePlace(place){
        this.property.place = place
				this.property.address = place.formatted_address
      },
      setType(){
        axios.get(process.env.VUE_APP_APIURL + '/propertiesSubtypes', {
          params :{
            propertyType: this.property.type
          }})
        .then(response => {
          this.subtypes = response.data
        })
      },

      deleteItems(i, item){
        axios.delete(process.env.VUE_APP_APIURL + `/propertiesPhotos/${item[i].uid}` ,{
            headers: {
              'Access-Token': localStorage.getItem('token'),
            }
          })
          .then(res => {
            if(res){
              this.property.photos.splice(i, 1);
            }
          })
      },

      openPhotos(url){
        window.open(url)
      },

      openUser(uid) {
        open(
          this.$router.resolve({ name: "user", params: { userUid: uid } }).href,
          "_blank"
        );
      },

      addPhoto(){
        if (this.photo){
          axios.post(process.env.VUE_APP_APIURL + '/propertiesPhotos/', this.photo ,{
            headers: {
              'Access-Token': localStorage.getItem('token'),
            },
            params: {
              propertyUid: this.$route.params.propertyUid,
              position: 0,
            },
          })
          .then(response => {
            this.property.photos.unshift(response.data)
            this.photo = {}
          })
        } else {
          return
        }
      },

      addVideo(){
        axios.post(process.env.VUE_APP_APIURL + '/properties/' + this.$route.params.propertyUid + '/videos', this.video ,{
          headers: {
            'Access-Token': localStorage.getItem('token'),
          },
        })
        .then(response => {
          this.property.videos.push(response.data)
          this.video = {}
        })
      },

      getNumber(id) {
        const equpmt = this.property.equipmentsLinks 
                        ? this.property.equipmentsLinks.find( el =>  el.propertyEquipment == id)
                        : false
				return equpmt
					? equpmt.count
					: 0;
			},

			setNumber($event, id) {

				this.updated = true;
				const equpmt = this.property.equipmentsLinks.find( el =>  el.propertyEquipment == id)
				if ( equpmt ) {
					equpmt.count = $event
				} else {
					this.property.equipmentsLinks.push({ propertyEquipment: id, count : $event }) 
				}
			},

      // addOwner(){
      //   axios.post(process.env.VUE_APP_APIURL + '/properties/' + this.$route.params.propertyUid + '/owners', {
      //     birthdate: new Date(this.birth.split('/').reverse().join('-')),
      //     email: this.email,
      //     firstname: this.firstname,
      //     lastname: this.lastname,
      //     phone: this.phone,
      //   },{
      //     headers: {
      //       'Access-Token': localStorage.getItem('token'),
      //     },
      //   })
      //   .then(res => {
      //     this.property.owners.push(res.data)
      //   })
      //   .catch(err => {
      //     console.log(err.response.data)
      //   })
      // },

      deleteProperty(){
        if(confirm('Supprimer cet élément ?')){
          axios.delete(process.env.VUE_APP_APIURL + '/properties/' + this.$route.params.propertyUid, {
            headers:{
              'Access-Token': localStorage.getItem('token')
            }
          }).then(res => {
            if(res.status == 200 ) {
              window.close();
            }
          })
          .catch(err => {
            console.error(err)
          })
        }
      },
    },
}
</script>

<style>

  form{
    max-width: 600px;
    margin: auto;
  }

  #pac-card{
    display: flex;
    position: initial;
    background: none;
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0 8px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
    box-shadow: none;
    border-bottom: 1px solid rgb(156, 154, 154);
  }

  .pac-input-container {
    width: 100%;
    padding: 0px;
  }

  .info{
    margin-bottom: 2rem;
  }
  
  .containerPhotos {
    margin-bottom: 2rem;
  }

  .photos{
    object-fit: contain;
  }

  .photosButton{
    display: flex;
    justify-content: space-between;
    width: 30%;
  }

  .videos{
    width: 250px;
  }
  .videos:focus{
    outline: none;
  }

  @media all and (max-width: 600px) {
    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    .photos{
      max-width: 100%;
    }

    form {
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }
  }
</style>