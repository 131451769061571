<template>
  <div class="top" id="top" @click="backToTop()">
    <div class="top__content">
      <img class="logo" src="../assets/next.svg" alt="">
      <p>Back to top</p>
    </div>
  </div>
</template>

<script>
export default {
  mounted(){
    const backToTop = document.getElementById('top');
    window.addEventListener('scroll', () => {
      if(window.pageYOffset > 300){
        backToTop.style.display = "block"
        
      }else{
        backToTop.style.display = "none"
      }
    })
  },
  methods:{
    backToTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style >

  .top{
    position: fixed;
    cursor: pointer;
    right: 50px;
    bottom: 10px;
    display: none;
  }

  .top__content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo{
    transform: rotate(-90deg);
    width: 30px;
  }

  @media all and (max-width: 768px) {
    .top{
      visibility: hidden;
    }
  }
</style>