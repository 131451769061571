<!-- 
	Refacted 26/12
 -->


<template>

	<label class="pdl-checkbox" :class="!display ? 'pdl-default' : 'pdl-' + display">

		<input  
			type="checkbox" 
			:value="val"
			v-model="data"
			@change= "update"
		/>


		<div v-if="!display">

			<svg v-if="value == val"  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_i)">
				<rect width="18" height="18" rx="4" fill="#4F8A8B"/>
				</g>
				<path d="M13.2758 6L7.85841 11.4174L5.7242 9.28318L5 10.0074L7.85841 12.8658L14 6.7242L13.2758 6Z" fill="white"/>
				<defs>
				<filter id="filter0_i" x="0" y="0" width="18" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix"/>
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				<feOffset dy="4"/>
				<feGaussianBlur stdDeviation="2"/>
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
				</filter>
				</defs>
			</svg>

			<svg  v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_i)">
				<rect width="18" height="18" rx="4" fill="white"/>
				</g>
				<rect x="0.25" y="0.25" width="17.5" height="17.5" rx="3.75" stroke="#C0C0C0" stroke-width="0.5"/>
				<defs>
				<filter id="filter0_i" x="0" y="0" width="18" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix"/>
				<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
				<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
				<feOffset dy="4"/>
				<feGaussianBlur stdDeviation="2"/>
				<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
				<feColorMatrix type="matrix" values="0 0 0 0 0.35 0 0 0 0 0.35 0 0 0 0 0.35 0 0 0 0.25 0"/>
				<feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
				</filter>
				</defs>
			</svg>
		</div>

		<div v-if="label">
			{{label}}
		</div>
		<div v-else>
			<slot></slot>
		</div>

		
		<span v-if="display == 'switch'" class="pdl-switch-tool"></span>
	</label>
		
</template>


<script>

	export default {

  		props: {   
	        display: {
	        	type : String,
	        	default : ""
	        },
	        label: String,
	        val: [String, Object, Number],
	        value: [Array, Boolean, String, Number]
	     },


	    data() {
	     	return {
	     		data: this.value
	     	}
	    },
			
		methods: {

			update($event) {

				let result = event.target.checked;

				if ( Array.isArray(this.value) ) {

					result = [...this.value];

					const i = result.findIndex( el => JSON.stringify(el) == JSON.stringify(this.val) );

					(i == -1) 
						? result.push(this.val)
						: result.splice(i, 1);
				}

				this.$emit('input', result);
			}
		}
 	}

</script>


<style scoped>


	/* DISPLAY */

	/* -default */

	label.pdl-checkbox.pdl-default, label.pdl-checkbox.pdl-switch {
		display: flex;
		align-items: flex-start;
	    margin-top: 1.7rem;
	    font-size: 1.3rem;
	}
	label.pdl-checkbox.pdl-default > input {
		display: none;
	}
	label.pdl-checkbox.pdl-default > div {
	    padding-left: .6rem;
	}
	label.pdl-checkbox.pdl-default svg {
		cursor: pointer;
	}


	/* -naked */
	label.pdl-checkbox.pdl-naked > input {
		display: none;
	}


	/* -switch */

	label.pdl-checkbox.pdl-switch {
		justify-content: space-between;
		align-items: center;
    	padding: .9rem 0;
    	margin-top: 0rem;
	}
	label.pdl-checkbox.pdl-switch input {
		visibility: hidden;
		display: none;
	}
	label.pdl-checkbox.pdl-switch .pdl-switch-tool {
		position: relative;
		display: block;
		min-width: 6rem;
		max-width: 6rem;
    	height: 1.9rem;
		appearance: none;
		background: #000;
		outline: none;
		border-radius: 20px;
	    cursor: pointer;
		transition: background .1s ease-out;
	}
	label.pdl-checkbox.pdl-switch .pdl-switch-tool::before {
		content: 'Non';
		position: absolute;
		width: auto;
		color: #fff;
		top: .3rem;
		right: 50%;
		font-size: 1.1rem;
		transition: right .3s ease-out;
	}
	label.pdl-checkbox.pdl-switch .pdl-switch-tool::after {
		content: '';
		position: absolute;
		top: .5rem;
		right: .8rem;
		border: solid 1.5px #fff;
		width: .7rem;
		height: .7rem;
		border-radius: 50%;
		transition: right .3s ease-out;
	}
	label.pdl-checkbox.pdl-switch input[type="checkbox"]:checked ~ .pdl-switch-tool::before {
		content: 'Oui';
		left: auto;
		right: 1rem;
	}
	label.pdl-checkbox.pdl-switch input[type="checkbox"]:checked ~ .pdl-switch-tool::after {
		background: #fff;
		right: calc(100% - 1.6rem);
	}
	label.pdl-checkbox.pdl-switch input[type="checkbox"]:checked ~ .pdl-switch-tool {
		background: #4F8A8B;
	}

</style>


