<template>
    <v-row justify="center">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>{{ title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <slot/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style>

</style>